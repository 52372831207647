.contact-container {
  z-index: 1;
  background-color: #12151f;
  height: 800px;
  width: 95%;
  border-radius: 10px;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(white, #12151f);
  box-shadow: 5px 5px 20px #12151f;
}

.contact-text {
  text-align: center;
  background: url("https://i.imgur.com/4vzyZMe.gif");
  background-size: cover;
  font-size: 100px;
  font-family: "Righteous", cursive;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 25px 0px 25px 0px;
}

.form-container {
  width: 300px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container button {
  margin-top: 25px;
}

@media only screen and (max-width: 750px) {
  .contact-text {
    height: auto;
    padding: 5px;
    font-size: 70px;
    margin: 10px 0px 0px 0px;
  }
}
