.projects-container {
  z-index: 1;
  background-color: #12151f;
  width: 95%;
  border-radius: 10px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: top;
  background-image: linear-gradient(white, #12151f);
  box-shadow: 5px 5px 20px #12151f;
  padding-bottom: 15px;
}

.projects-text {
  text-align: center;
  background: url("https://i.imgur.com/4vzyZMe.gif");
  background-size: cover;
  font-size: 100px;
  font-family: "Righteous", cursive;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding-bottom: 10px;
  margin: 25px 0px 15px 0px;
}

.projects {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 15px;
  width: 90%;
  font-family: "Raleway", sans-serif;
}

.project-container {
  background-color: rgba(255, 255, 255, 0.5);
  max-width: 600px;
  text-align: center;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 5px 5px 20px #12151f;
}

.project-image {
  height: 200px;
  width: auto;
  margin: 10px;
  border-radius: 5px;
}

.project-name {
  font-family: "Righteous", cursive;
  letter-spacing: 1px;
  font-size: 2rem;
  margin: 0px 10px 10px 10px;
}

.project-description {
  text-align: justify;
  margin: 10px;
}

.project-buttons {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  margin: 25px 0px 10px 0px;
}

@media only screen and (max-width: 750px) {
  .projects-text {
    height: auto;
    padding: 5px;
    font-size: 70px;
    margin: 10px 0px 0px 0px;
  }

  .project-image {
    height: 150px;
  }
}
