.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App {
  display: flex;
  justify-content: center;
}

.about-container {
  z-index: 1;
  background-color: #12151f;
  width: 95%;
  border-radius: 10px;
  margin-top: -300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(white, #12151f);
  box-shadow: 5px 5px 20px #12151f;
}

.about-text {
  text-align: center;
  background: url("https://i.imgur.com/4vzyZMe.gif");
  background-size: cover;
  font-size: 100px;
  font-family: "Righteous", cursive;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 25px 0px 25px 0px;
}

.info-box {
  max-width: 700px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  font-size: large;
  font-family: "Raleway", sans-serif;
  margin: 25px;
}

.profile-photo {
  height: 300px;
  width: 300px;
  border-radius: 40% 15% 40%;
  border: 1px solid #12151f;
  margin: 25px;
}

.brand-statement {
  display: flex;
  flex-direction: column;
  height: auto;
  text-align: left;
}

#resume-button {
  margin-top: 25px;
}

.language-box {
  width: 50%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 25px;
}

#css3-logo,
#html5-logo,
#javascript-logo,
#nodejs-logo,
#react-logo,
#rails-logo,
#ruby-logo {
  background-color: white;
  box-shadow: 5px 5px 20px #05f4b7;
  margin: 5px;
  border-radius: 5px;
}

.tech-header {
  font-family: "Righteous", cursive;
  font-size: 3rem;
  letter-spacing: 2px;
  color: white;
  text-shadow: 0px 0px 5px #05f4b7;
  text-shadow: 0px 0px 20px #05f4b7;
  margin-bottom: 25px;
}

@media only screen and (max-width: 750px) {
  .about-text {
    width: 200px;
    height: auto;
    padding: 5px;
    font-size: 70px;
    margin: 25px 0px 0px 0px;
  }

  /* .about-container {
    height: 900px;
  } */

  .info-box {
    flex-direction: column;
  }
  .profile-photo {
    height: 200px;
    width: 200px;
    margin: 0px 0px 25px 0px;
  }
  .brand-statement {
    text-align: center;
  }
  .tech-header {
    font-size: 2rem;
  }
}
