.nav-bar {
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #12151f;
  font-family: "Raleway", sans-serif;
  position: fixed;
  width: 95%;
  background-color: white;
  border-radius: 10px;
  z-index: 3;
}

.nav-bar h1 {
  margin-left: 25px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}

.logo {
  background-color: #371bb1;
  background: url("https://i.imgur.com/2XRzqYX.png");
  background-position: center;
  background-size: 65px 65px;
  background-repeat: no-repeat;
  height: 75px;
  width: 75px;
  border-radius: 100%;
}

.logo:hover {
  transition: all 0.6s ease-in-out;
  transform: rotate(360deg);
}

.nav-links {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin-right: 25px;
}

.nav-links a {
  text-decoration: none;
  color: #12151f;
  transform: rotate(-45deg);
  cursor: pointer;
}

.nav-links > .active {
  color: #371bb1;
  font-weight: bold;
  text-shadow: 5px 5px 20px #371bb1;
}

.nav-links a:hover {
  color: #05f4b7;
  transition: all 0.6s ease-in-out;
  transform: rotate(315deg);
}

.filter {
  background-image: linear-gradient(rgba(255, 0, 0, 0), #12151f);
  height: 100%;
  width: 100%;
}
