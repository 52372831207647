.home-container {
  height: 900px;
  width: 100%;
  /* background-image: url("../assets/jonatan-pie-stars.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home-text {
  height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  z-index: 1;
  color: white;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  text-shadow: 0px 0px 10px #05f4b7, 0px 0px 20px #05f4b7;
}

.home-text h1 {
  font-size: 4rem;
  margin: 0px;
  font-family: "Righteous", cursive;
  /* text-shadow: 0px 0px 10px #05f4b7, 0px 0px 20px #05f4b7; */
}

.icon-button {
  color: white;
}
